#chat {
  padding-bottom: 0;
  max-height: none;
  height: calc(100vh - 11.5rem);
}

.chat-wrapper {
  overflow-y: hidden;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.chat {
  height: calc(100vh - 11.5rem);
  overflow-y: scroll;
  padding: 0 0.625rem;
}

.chat::-webkit-scrollbar {
  width: 0.25rem;
}

.chat::-webkit-scrollbar-track {
  background-color: var(--dark);
  background-color: #0d0e17;
}

.chat::-webkit-scrollbar-thumb {
  background-color: var(--light);
}

#chat-form {
  margin: 0;
}

.chat-input {
  position: relative;
  width: 100%;
}

#message-input {
  /* background: var(--dark); */
  margin: var(--mg-l) 0;
  width: 100%;
  max-width: none;
  font-weight: 400;
  font-size: 1rem;
  padding-right: 2.25rem;
}

.chat-submit {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-40%);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .chat {
    height: calc(100vh - 17.625rem);
    padding: 0 1.25rem;
  }

  .chat-submit {
    font-size: 1.5rem;
  }

  #message-input {
    margin: calc(0.5 * var(--mg-l)) 0;
    font-size: 1.125rem;
    padding-right: 2.75rem;
  }
}
