.loader {
  border-radius: 50%;
  border: calc(0.325 * 1rem) solid var(--dark);
  border-top: calc(0.325 * 1rem) solid var(--orange);
  width: calc(2.25 * 1rem);
  height: calc(2.25 * 1rem);
  -webkit-animation: spin 1.75s linear infinite; /* Safari */
  animation: spin 1.75s linear infinite;
}

@media screen and (min-width: 768px) {
  .loader {
    border-radius: 50%;
    border: calc(0.35 * 1.5rem) solid var(--dark);
    border-top: calc(0.35 * 1.5rem) solid var(--orange);
    width: calc(2.5 * 1.5rem);
    height: calc(2.5 * 1.5rem);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
