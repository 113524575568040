@font-face {
  font-family: 'CircularStd';
  src:
    local('Circular Std Black'),
    url('./../fonts/circular_std_black.ttf')
    format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src:
    local('Circular Std Bold'),
    url('./../fonts/circular_std_bold.ttf')
    format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src:
    local('Circular Std Medium'),
    url('./../fonts/circular_std_medium.ttf')
    format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd';
  src:
    local('Circular Std Book'),
    url('./../fonts/circular_std_book.ttf')
    format('truetype');
  font-weight: 400;
  font-display: swap;
}

:root {
  --mg-l: 1rem;

  /* Colors */
  --dark: #141622;
  --dim: #222632;
  --dimmer: #333541;
  --light: #fff;
  --purple: #8540f5;
  --orange: #f47912;

  /* --purple: #312d6a; */
}

@media screen and (min-width: 768px) {
  :root {
    --mg-l: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --mg-l: 3rem;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'CircularStd', sans-serif;
  font-weight: 500;
  background-color: var(--dark);
  color: var(--light);
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background-color: var(--dark);
  background-color: #0d0e17;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--light);
}

#root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

section {
  margin-top: 4rem;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 4rem);
  padding: calc(1.5 * var(--mg-l)) var(--mg-l);
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-weight: 600;
}

h3 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  font-weight: 500;
}

.webview a {
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  section {
    min-height: calc(100vh - 6rem);
    padding: calc(0.5 * var(--mg-l)) var(--mg-l);
    margin-top: 6rem;
  }

  h2 {
    font-size: 2rem;
    margin: 1rem 0;
  }

  h3 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 80%;
  }

  h2 {
    font-size: 2.5rem;
    margin: 1rem 0;
  }

  h3 {
    font-size: 1.75rem;
  }
}
