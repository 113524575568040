.members {
  max-width: 30rem;
  margin: 2rem 0;
  width: 100%;
}

.members h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

.members-list {
  margin: 1rem 0;
}

.member {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.member-pfp {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.member-pfp img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.member-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.host {
  margin-left: auto;
  font-size: 0.75rem;
  padding: 0.125rem;
  border: 0.125rem var(--orange) solid;
  border-radius: 0.25rem;
}

.kick {
  margin-left: auto;
  font-size: 1rem;
  color: #ff5e5e;
  cursor: pointer;
  transition: all 1ms ease;
}

.kick:hover {
  transform: scale(1.25);
}

@media screen and (min-width: 1200px) {
  .members h3 {
    font-size: 1.75rem;
  }

  .members {
    margin-top: 0;
  }

  .member {
    border: 0.175rem solid var(--dimmer);
    border-radius: 0.5rem;
    background: var(--dim);
    padding: 1rem;
    font-size: 1.25rem;
  }

  .host {
    font-size: 1rem;
  }

  .kick {
    font-size: 1.25rem;
  }
}
